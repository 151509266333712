import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

const OuterContainer = styled.div`
  height: 78vh;
  // width:75%
`
const Hello = styled.h1`
  margin-top: 1rem;
`

const Description = styled.p`
  padding: 0;
  margin-bottom: 1rem;
  font-size: 2rem;
  line-height: 1.2;
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
`
const LinkText = styled.a`
  color: black;
  text-decoration: none;
`

const Email = styled.p`
  padding: 0;
  margin-bottom: 1rem;
  font-size: 1rem;
`

const NameHeader = styled.h1`
  // font-size: 3.5rem;
  // margin-bottom: 0;
`

const LandingBio = () => {
  return (
    <OuterContainer>
      <Hello>Hello! 👋🏽 </Hello>
      <Description>
        My name is Satej Sawant.
        <br /> I'm a Software Developer based in New York City, 🇺🇸.
        <br /> <br />
        Last year, I graduated from{" "}
        <LinkText href="https://www.rpi.edu/">
          {" "}
          Rensselaer Polytechnic Institute
        </LinkText>{" "}
        with a Masters in Information Technology.
        <br /> <br />
        Currently, I'm working with <LinkText href="https://aws.amazon.com/iot/">AWS IoT</LinkText> as a Software Developer.
      </Description>
    </OuterContainer>
  )
}

NameHeader.propTypes = {
  siteTitle: PropTypes.string,
  subtitle: PropTypes.string,
}

NameHeader.defaultProps = {
  siteTitle: ``,
  subtitle: ``,
}

export default LandingBio
